.container {
  max-width: 80%;
  margin: auto;
}
.img{
    width: 100%;
    height: 5rem;
}
.container-image{
  width: 100px;
  
}
button {
  display: inline-block;
  background: #fff;
  color: rgb(217, 31, 37);
  outline: none;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  transition: 0.5s;
  cursor: pointer;
}

button:hover {
  background: #776e73;
  box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
}

@media screen and (max-width: 768px) {
  .left,
  .right {
    width: 100%;
  }
  .about .container {
    flex-direction: column;
  }
  .about .img {
    width: 100%;
    height: 100%;
  }
  .about img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .about .img::after {
    display: none;
  }
}

@media screen and (max-width:940px){
  .accordian{
    max-width: 800px;
  }
}
