.container {
  max-width: 80%;
  margin: auto;
  margin: 2rem auto;
  padding: 2rem 0;
}
.service-container{
    max-width: 80%;
    text-align: center;
    margin: 3rem auto;
    width: 1000px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    justify-content: space-between;
    color: #fff;
    user-select: none;
}
.service_card{
    position: relative;
    background: #112047;
    width: 500px;
    height: 370px;
    padding: 20px;
    margin: 0 auto;
    transition: all 400ms ease;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
}

.card .image-box{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.service_card .image-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    display: block;
    transition: 0.5s;
}
.service_card .service-content{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    padding: 12px;
    background: #112047;
    transition: all 0.5s cubic-bezier(.48, -0.28, 0.41, 1.4);
    box-sizing: border-box;
    overflow: hidden;
}
.service_card:hover .service-content{
    width: 100%;
    height: 75%;
    left: 0;
    bottom: 0;
}
.service-content p{
    margin: 5px 0 2px 0;
    padding: 1em 1em;
    transform: translateY(2em);
    line-height: 1.4;
    transition: 0.3s;
    text-align: justify;
}
.service_card:hover .service-content p{
    transform: translateY(0);
    opacity: 1;
}
h2{
    text-align: center;
}
h3{
    text-align: center;
    margin: 2rem;
}
.service-p{
  margin: 2rem 0 2.6rem 2;
  color: #363636;
  line-height: 1.3;
  font-size: 18px;   
}
.service_card:hover{
    box-shadow: 0 0 5em rgba(0, 0, 0, 0.4);
}