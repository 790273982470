.navbar {
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    background-color: rgb(237, 229, 229);
}

.navbar .container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
}
.first__align{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar .logo {
    display: flex;
    align-items: center;
    margin: 0.9rem;
    color: #112047;
    z-index: 5;
    cursor: pointer;
}
.image{
    width: 100px;
    height: 50px;
    margin-left: 100px;
}
.hamburger {
    display: none;
    padding: 1rem;
    cursor: pointer;
    margin-right: 100px;
}

.mobile-menu {
    display: none;
}

.nav-menu {
    display: flex;
    
}

.nav-menu li {
    padding: 1rem;
    list-style: none;
    cursor: pointer;
    align-items: center;
}

.hide{
    margin-right: 5rem;
}

.nav-menu li a {
    color: #112047;
    font-size: 1rem;
    font-weight: 600;
}

@media screen and (max-width: 940px) {
    .hamburger {
        display: block;
        z-index: 5;
        margin-right: 50px;
    }

    .image{
        margin-left: 50px;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100vh;
        background: #fff;
        top: 0;
        z-index: -1;
        right: -100%;
        transition: 0.9s ease-in-out; 
    }
    .first__align{
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .second__align{
        margin-top: 300px;
    }
    .nav-menu li a{
        color: rgb(230, 222, 222);
        color: #000;
    }

    .active {
        right: 0;
    }

    .nav-menu li {
        margin: .5rem;
        list-style: none;
    }

    .nav-menu li a {
        font-size: 1.2rem;
    }
   
    .nav-menu .icon {
        color: #f8f8f8cc;
        font-size: 1.4rem;
    }

    .hide {
        display: none;
    }

   .navbar .slide-right {
        margin-left: 45%;
        transition: .8s ease-in-out;
    }
}
@media screen and (max-width: 495px) {
    .nav-menu {
        width: 75%;
    }

    .navbar .slide-right {
        margin-left: 30%;
    }

}